import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'

import Layout from '../layouts/default/layout';

const MapPage = () => {

  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)

  useEffect(()=>{
    setData(dataJSON)    
    mapActive()
  }, [])

  const mapActive = ()=>{
    let interval = setInterval(()=>{
        if(document.querySelector('ae-login-modal')){
            clearInterval(interval)
            let mapComponent = document.createElement('ae-bikemap')
            document.querySelector('.content-map').appendChild(mapComponent)

            
            var el = document.querySelector('ae-bikemap');

            el.addEventListener('toCheckout', function () {
                document.querySelector('ae-checkout-modal').open = true;
            })

            el.addEventListener('toLogin', function () {
                document.querySelector('ae-login-modal').show = true;
            })




        }
    }, 1000)
     
  }

  
  return (
    <>
      <title>{dataJSON.customer  ? dataJSON.customer.name + ' - Mapa de posição' : ''}</title>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >
        <div className="">
          <div className="row">
            <h3 className="text-3xl text-center w-full mt-24" style={{color:'var(--primary)'}}>Escolha sua posição</h3>
            <div className="col-md-12 content-map ">

            </div>
          </div>


        </div>
      </Layout>

    </>
  )
}

export default MapPage
